import { StyleSheet, ScrollView, Image, View, Dimensions } from "react-native";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecentRecommendations,
  selectRecentLoading,
  selectSortedRecents,
} from "../../slices/musicSlice";
import { Chip, Text, ActivityIndicator, withTheme } from "react-native-paper";
import { Helmet } from "react-helmet";
import { StandardSeo } from "../../constants/seo";
import { selectLogin } from "../../slices/loginSlice";
// @ts-ignore
type Props = {
  theme: any;
  navigation: any;
};

const Recents = ({ theme, navigation }: Props) => {
  const userstate = useSelector(selectLogin);
  // const [recents, setRecents] = useState([]);
  // const recents = useSelector(selectRecents);
  const loading = useSelector(selectRecentLoading);
  const dispatch = useDispatch();
  const recents = useSelector(selectSortedRecents);
  const screenWidth = Dimensions.get("window").width;

  useEffect(
    () => {
      if (userstate) {
        dispatch(getRecentRecommendations());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userstate, navigation]
  );

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Helmet></Helmet>

      <StandardSeo title="Recents | DjGPT" />
      <Text style={styles.title}>Recent recommendations</Text>

      {loading && (
        <ActivityIndicator
          animating={true}
          color={theme.colors.primary}
          style={{ marginBottom: 16 }}
        />
      )}

      {Object.values(recents).map((recommendation: any, index: number) => {
        return (
          <View
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Chip style={{ marginVertical: 16 }} mode="outlined">
              <Text style={{ fontSize: 16, fontWeight: "700" }}>
                {new Date(Object.keys(recents)[index]).toLocaleDateString(
                  "en-GB",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              </Text>
            </Chip>

            {recommendation &&
              recommendation.map((recommendation: any, index: number) => {
                const { artist, genre, mood, song, timestamp, albumArt } =
                  recommendation;
                return (
                  <View key={index}>
                    <Chip
                      key={index + song}
                      style={{ margin: 4, width: screenWidth - 32, maxWidth: 900}}
                      avatar={
                        <Image
                          source={{ uri: albumArt }}
                          style={{
                            width: 32,
                            height: 32,
                            borderRadius: 12,
                            marginVertical: 4,
                          }}
                        />
                      }
                    >
                      {artist + " - " + song}
                    </Chip>
                  </View>
                );
              })}
          </View>
        );
      })}

      {userstate && !loading && Object.values(recents).length === 0 && (
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            marginVertical: 16,
          }}
        >
          No recommendations yet!
        </Text>
      )}

      {!userstate && !loading && (
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            marginVertical: 16,
          }}
        >
          Login to see your recommendations!
        </Text>
      )}
    </ScrollView>
  );
};

export default withTheme(Recents);

const styles = StyleSheet.create({
  container: {
    maxWidth: 900,
    marginHorizontal: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
  },
  image: {
    width: "100%",
    height: 300,
    objectFit: "cover",
  },
  title: {
    fontSize: 24,
    fontWeight: "900",
    marginBottom: 16,
  },
  date: {
    textTransform: "uppercase",
    fontWeight: "700",
    marginHorizontal: "auto",
    fontSize: 16,
    marginVertical: 16,
  },
  subtitle: {
    fontWeight: "700",
    marginBottom: 16,
    fontSize: 18,
  },
  text: {
    fontSize: 16,
    marginBottom: 16,
  },
});
