// @ts-ignore
import { logEvent } from "../../utils/analytics";

export type Recommendation = {
  genre: string;
  mood: string;
  song: string;
};

const generateRecommendation = async ({
  genre,
  mood,
  song,
  previousSongs,
}: {
  genre?: string;
  mood?: string;
  song?: string;
  previousSongs?: any[];
}) => {
  try {
    console.log("body", {
      genre: genre,
      mood: mood,
      song: song,
      previousSongs: previousSongs,
    });

    const response = await fetch(
      "https://djgpt-reactway.vercel.app/api/music/song-rec",
      {
        method: "POST",
        body: JSON.stringify({
          genre: genre,
          mood: mood,
          song: song,
          previousSongs: previousSongs,
        }),
      }
    );
    // console.log("response", response);
    const data = await response.json();
    logEvent("generated_recommendation", {
      song: data.song,
      artist: data.artist,
      genre: data.genre,
      bpm: data.bpm,
    });
    // console.log("data", data);
    if (data.error || data.error === "Too many requests") {
      console.error(data);
      return {
        error: data.error,
      };
    }

    return data;
  } catch (err) {
    console.error(err);
  }
};

export default generateRecommendation;
