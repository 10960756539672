import { doc, getDoc, setDoc, Timestamp, arrayUnion } from "firebase/firestore";
import { db } from "../lib/firebase/firebase.web";
import { consoleLog } from "./helpers";
export const uploadRecentRecommendation = async (
  uid: string,
  recommendation: any[]
) => {
  const userRef = doc(db, "users", uid);
  const docSnap = await getDoc(userRef);
  const timestamp = Timestamp.now();
  // just the short date string

  const date = timestamp.toDate().toISOString().slice(0, 10);
  if (docSnap.exists()) {
    await setDoc(
      userRef,
      {
        recents: {
          [date]: arrayUnion(recommendation),
        },
      },
      { merge: true }
    );
  } else {
    await setDoc(
      userRef,
      {
        recents: {
          [date]: arrayUnion(recommendation),
        },
      },
      { merge: true }
    );
  }
};

export const getRecents = async (uid: string) => {
  const userRef = doc(db, "users", uid);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    consoleLog("recents", docSnap.data().recents);
    return docSnap.data().recents;
  } else {
    consoleLog("no recents");
    return null;
  }
};
