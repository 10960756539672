import { Helmet } from "react-helmet";

type StandardSeoProps = {
  url?: string;
  title?: string;
  description?: string;
  image?: string;
};

export const StandardSeo = ({
  url,
  title,
  description,
  image,
}: StandardSeoProps) => (
  <Helmet>
    <meta
      name="description"
      content={
        description ||
        "Unlock a universe of music with DjGPT. Explore millions of tracks, create seamless mixes, and discover new favorites—all with AI-powered recommendations."
      }
    />
    <meta
      name="keywords"
      content="music, ai, gpt-4, openai, dj, djgpt, recommendation, engine, song, songs, track, tracks, album, albums, artist, artists, genre, genres, mood, moods, playlist, playlists, mix, mixes, mi
xer, mixers, mixing, mixing, mix, mixer, mixing, mixtape, mixtapes, discover, discovery, discoverer, discoverers, discovery"
    />
    <meta name="author" content="DjGPT" />
    <meta
      name="og:title"
      property="og:title"
      content={title || "DjGPT - Music Recommendation Engine"}
    />
    <meta
      name="og:description"
      property="og:description"
      content={
        description ||
        "Unlock a universe of music with DjGPT. Explore millions of tracks, create seamless mixes, and discover new favorites—all with AI-powered recommendations."
      }
    />
    <meta
      name="og:image"
      property="og:image"
      content={image || "https://djgpt.co.uk/og-image.png"}
    />
    <meta
      name="og:url"
      property="og:url"
      content={url || "https://djgpt.co.uk"}
    />
    <meta name="og:site_name" property="og:site_name" content="DjGPT" />
    <meta name="og:type" property="og:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@djgpt" />
    <meta name="twitter:creator" content="@djgpt" />
    <meta
      name="twitter:title"
      property="twitter:title"
      content={title || "DjGPT - Music Recommendation Engine"}
    />
    <meta
      name="twitter:description"
      property="twitter:description"
      content={
        description ||
        "Unlock a universe of music with DjGPT. Explore millions of tracks, create seamless mixes, and discover new favorites—all with AI-powered recommendations."
      }
    />
    <meta
      name="twitter:image"
      property="twitter:image"
      content={image || "https://djgpt.co.uk/og-image.png"}
    />
    <title>{title || "DjGPT - Music Recommendation Engine"}</title>
    <link rel="canonical" href={url || "https://djgpt.co.uk"} />

    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://djgpt.co.uk",
        "name": "DjGPT",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+44 1234567890",
          "contactType": "Customer service"
        }
      }
      `}
    </script>
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://djgpt.co.uk/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://djgpt.co.uk/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }
      `}
    </script>
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "DjGPT",
        "url": "https://djgpt.co.uk",
        "sameAs": [
          "https://www.facebook.com/djgpt",
          "https://www.instagram.com/djgpt",
          "https://www.linkedin.com/in/djgpt",
          "https://twitter.com/djgpt"
        ]
      }
      `}
    </script>

    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "MusicGroup",
        "name": "DjGPT",
        "url": "https://djgpt.co.uk",
        "sameAs": [
          "https://www.facebook.com/djgpt",
          "https://www.instagram.com/djgpt",
          "https://www.linkedin.com/in/djgpt",
          "https://twitter.com/djgpt"
        ]
      }
      `}
    </script>

    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "MusicAlbum",
        "name": "DjGPT",
        "url": "https://djgpt.co.uk",
        "sameAs": [
          "https://www.facebook.com/djgpt",
          "https://www.instagram.com/djgpt",
          "https://www.linkedin.com/in/djgpt",
          "https://twitter.com/djgpt"
        ]
      }
      `}
    </script>

    <script type="application/ld+json">
      {`
        {
            "@context": "https://schema.org",
            "@type": "MusicRecording",
            "name": "DjGPT",
            "url": "https://djgpt.co.uk",
            "sameAs": [
            "https://www.facebook.com/djgpt",
            "https://www.instagram.com/djgpt",
            "https://www.linkedin.com/in/djgpt",
            "https://twitter.com/djgpt"
            ]
        }
        `}
    </script>

    <script type="application/ld+json">
      {`
        {
            "@context": "https://schema.org",
            "@type": "MusicPlaylist",
            "name": "DjGPT",
            "url": "https://djgpt.co.uk",
            "sameAs": [
            "https://www.facebook.com/djgpt",
            "https://www.instagram.com/djgpt",
            "https://www.linkedin.com/in/djgpt",
            "https://twitter.com/djgpt"
            ]
        }
        `}
    </script>

    <script type="application/ld+json">
      {`
        {
            "@context": "https://schema.org",
            "@type": "MusicComposition",
            "name": "DjGPT",
            "url": "https://djgpt.co.uk",
            "sameAs": [
            "https://www.facebook.com/djgpt",
            "https://www.instagram.com/djgpt",
            "https://www.linkedin.com/in/djgpt",
            "https://twitter.com/djgpt"
            ]
        }
        `}
    </script>

    <script type="application/ld+json">
      {`
        {
            "@context": "https://schema.org",
            "@type": "MusicEvent",
            "name": "DjGPT",
            "url": "https://djgpt.co.uk",
            "sameAs": [
            "https://www.facebook.com/djgpt",
            "https://www.instagram.com/djgpt",
            "https://www.linkedin.com/in/djgpt",
            "https://twitter.com/djgpt"
            ]
        }
        `}
    </script>

    
  </Helmet>
);
