import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Path, Svg } from "react-native-svg";

type Props = {
  color: string;
};

const Tidal = ({ color }: Props) => {
  return (
    <Svg fill={color} width="24px" height="24px" viewBox="0 0 32 32">
      <Path d="M16.016 5.323l-5.339 5.339-5.339-5.339-5.339 5.339 5.339 5.339 5.339-5.339 5.339 5.339-5.339 5.339 5.339 5.339 5.339-5.339-5.339-5.339 5.339-5.339zM21.391 10.661l5.302-5.307 5.307 5.307-5.307 5.307z" />
    </Svg>
  );
};

export default Tidal;
