import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { webauth } from "../firebase/firebase.web";
import { isWeb } from "../utils";

const provider = new GoogleAuthProvider();

export const loginWeb = () => {
  // use googleauthprovider to sign in

  signInWithPopup(webauth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log({ credential, token, user });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // console.log({ errorCode, errorMessage, email, credential });
    });
};

export const logout = () => {
  console.log("logging out");
  webauth.signOut();
};

export const deleteAccount = () => {
  // sign out user and delete account
  webauth.currentUser?.delete();
};

export const loggedIn = () => {
  return webauth.currentUser;
};
