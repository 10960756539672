import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Path, Svg } from "react-native-svg";

type Props = {
  color: string;
};

const Deezer = ({ color }: Props) => {
  return (
    <Svg
      fill={color}
      width="24px"
      height="24px"
      viewBox="-2 -6.5 24 24"
      preserveAspectRatio="xMinYMin"
    >
      <Path d="M0 9.786h3.64v1.062H0zM0 8.405h3.64v1.062H0zM0 7.025h3.64v1.062H0zM0 5.645h3.64v1.062H0zM0 4.264h3.64v1.062H0zM4.082 9.786h3.641v1.062H4.082zM4.082 8.405h3.641v1.062H4.082zM4.082 7.025h3.641v1.062H4.082zM8.164 9.786h3.641v1.062H8.164zM8.164 8.405h3.641v1.062H8.164zM8.164 7.025h3.641v1.062H8.164zM8.164 5.645h3.641v1.062H8.164zM8.164 4.264h3.641v1.062H8.164zM8.164 2.884h3.641v1.062H8.164zM8.164 1.503h3.641v1.062H8.164zM12.246 9.786h3.641v1.062h-3.641zM12.246 8.405h3.641v1.062h-3.641zM12.246 7.025h3.641v1.062h-3.641zM12.246 5.645h3.641v1.062h-3.641zM12.246 4.264h3.641v1.062h-3.641zM16.328 9.786h3.641v1.062h-3.641zM16.328 8.405h3.641v1.062h-3.641zM16.328 7.025h3.641v1.062h-3.641zM16.328 5.645h3.641v1.062h-3.641zM16.328 4.264h3.641v1.062h-3.641zM16.328 2.884h3.641v1.062h-3.641zM16.328 1.503h3.641v1.062h-3.641zM16.328.122h3.641v1.062h-3.641z" />
    </Svg>
  );
};

export default Deezer;
