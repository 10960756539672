import { ScrollView, StyleSheet, View } from "react-native";
import { Text, Avatar, withTheme } from "react-native-paper";
import { useSelector } from "react-redux";
import { selectLogin } from "../../slices/loginSlice";
import { Helmet } from "react-helmet";
// @ts-ignore
import SignIn from "../login/SignIn";
import { StandardSeo } from "../../constants/seo";
import Logo from "../assets/Logo";
type Props = {
  theme: any;
};

const Splash = ({ theme }: Props) => {
  const userState = useSelector(selectLogin);
  const styles = StyleSheet.create({
    container: {
      justifyContent: "flex-start",
      gap: 16,
      paddingHorizontal: 16,
      maxWidth: 800,
      marginHorizontal: "auto",
      backgroundColor: theme.colors.background,
      height: "100%",
    },
    loginContainer: {
      justifyContent: "center",
      alignItems: "center",
      maxWidth: 800,
      gap: 23,
      height: "100%",
      marginHorizontal: "auto",
      backgroundColor: theme.colors.background,
    },
    title: {
      fontSize: 24,
      fontWeight: "900",
      marginBottom: 16,
    },
    subtitle: {
      fontWeight: "700",
      marginBottom: 16,
      fontSize: 18,
    },
    text: {
      fontSize: 16,
      marginBottom: 16,
    },
  });
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      style={{
        backgroundColor: theme.colors.background,
      }}
    >
      <Helmet></Helmet>

      <StandardSeo title="Account | DjGPT" />
      <View style={styles.loginContainer}>
        <Logo color={theme.colors.primary} width={150} height={150} />
        <Text style={styles.title}>DjGPT</Text>
        <SignIn />
      </View>
    </ScrollView>
  );
};

export default withTheme(Splash);
