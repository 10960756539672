import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LoginState {
  nav: {
    index?: number;
  };
}

const initialState: LoginState = {
  nav: {
    index: 0,
  },
};

export const LoginSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setIndex: (state, action) => {
      state.nav.index = action.payload;
    },
  },
});
// Selectors
export const selectIndex = (state: RootState) => state.nav.nav.index;

//  Actions
export const { setIndex } = LoginSlice.actions;

export default LoginSlice.reducer;
