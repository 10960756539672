import {
  addSong,
  detectLoading,
  selectDetectLoading,
} from "../../slices/musicSlice";
import React from "react";
import { Button, Dialog, Portal, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { Audio as AudioAV } from "expo-av";
import { isWeb, returnOSValue } from "../../lib/utils";
import * as FileSystem from "expo-file-system";
import { StyleSheet } from "react-native";
import { consoleLog } from "../../utils/helpers";
// @ts-ignore
import { logEvent } from "../../utils/analytics";

type RecommendationProps = {};

const Detect: React.FC<RecommendationProps> = ({}) => {
  const loadingState = useSelector(selectDetectLoading);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [recording, setRecording] = React.useState() as any;
  const [detectedSong, setDetectedSong] = React.useState() as any;
  const [error, setError] = React.useState(false);

  const convertWebmToWav = (webm: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
        // console.log(reader.result);
      };
      reader.readAsDataURL(webm);
    });
  };

  const recordAudio = () => {
    return new Promise(async (resolve) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: any[] = [];
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioChunks.push(event.data);
        });
        const start = () => {
          mediaRecorder.start();
          setRecording(true);
        };
        const stop = () => {
          return new Promise((resolve) => {
            mediaRecorder.addEventListener("stop", () => {
              const audioBlob = new Blob(audioChunks);
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              const play = () => {
                audio.play();
              };
              // remove access to microphone

              if (stream.active) {
                stream.getTracks().forEach((track) => {
                  track.stop();
                });
              }

              resolve({ audioBlob, audioUrl, play });
            });
            mediaRecorder.stop();
            setRecording(false);
            // setLoading(true);
            dispatch(detectLoading(true));
          });
        };
        const cancel = () => {
          if (stream.active) {
            stream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          setRecording(false);
        };

        resolve({ start, stop, cancel });
      } catch (err) {
        console.error(err);
      }
    });
  };
  const sendWav = async (blob: Blob) => {
    try {
      setError(false);
      const response = await fetch(
        // `${
        //   isWeb ? "https://cors-anywhere.herokuapp.com/" : ""
        // }${"https://djgpt-reactway.vercel.app/api/music/detectrn"}`,
        "https://djgpt-reactway.vercel.app/api/music/detectrnshazam",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            song: blob,
            os: returnOSValue({
              androidValue: "android",
              iosValue: "ios",
              webValue: "web",
            }),
          }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      if (data?.message === "success - matches found") {
        dispatch(detectLoading(false));
        setDetectedSong(data);
        setModalOpen(true);
      }
      if (data?.message === "success - no matches found") {
        dispatch(detectLoading(false));
        setDetectedSong(data);
        setModalOpen(true);
      }
      if (data?.code === "error" || data?.error) {
        setError(true);
        setDetectedSong(data);
        setModalOpen(true);
        dispatch(detectLoading(false));
      }
    } catch (err) {
      setError(true);
      dispatch(detectLoading(false));
      console.error(err);
    }
  };

  const handleDetect = async () => {
    // consoleLog("handleDetect");
    logEvent("detect");
    try {
      dispatch(detectLoading(true));
      if (!isWeb) {
        // console.log("Requesting permissions..");
        await AudioAV.requestPermissionsAsync();
        await AudioAV.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        });
        // console.log("Starting recording..");
        const { recording } = await AudioAV.Recording.createAsync(
          AudioAV.RecordingOptionsPresets.HIGH_QUALITY
        );
        // console.log("recording", recording);

        setRecording(recording) as Blob;
        // console.log("Recording started");
        setTimeout(async () => {
          // console.log("Stopping recording..");
          recording.stopAndUnloadAsync();
          // console.log("Recording stopped and stored at", recording.getURI());

          if (recording !== null) {
            // console.log("notWeb");
            // convert to blob to send to server
            const info = await FileSystem.getInfoAsync(
              recording.getURI() as string
            );
            // console.log(`FILE INFO: ${JSON.stringify(info)}`);

            const blob = await FileSystem.readAsStringAsync(
              recording.getURI() as string,
              {
                encoding: FileSystem.EncodingType.Base64,
              }
            );

            // console.log("blob", blob);
            // @ts-ignore
            sendWav(blob);
          }
        }, 3800);
      }
      if (isWeb) {
        // console.log("isWeb");
        const recorder = (await recordAudio()) as any;
        recorder.start();
        setTimeout(async () => {
          const audio = await recorder.stop();
          // console.log("audio", audio);
          const blob = await convertWebmToWav(audio.audioBlob);
          // console.log("blob", blob);
          // @ts-ignore
          sendWav(blob);
        }, 3800);
      }
    } catch (err) {
      dispatch(detectLoading(false));
      console.error("Failed to start recording", err);
    }
  };
  return (
    <>
      <Portal>
        <Dialog visible={modalOpen} onDismiss={() => setModalOpen(false)}>
          <Dialog.Icon
            icon={
              (detectedSong?.message === "success - matches found" &&
                "music-note-sixteenth") ||
              (detectedSong?.message === "success - no matches found" &&
                "music-note-off-outline") ||
              (detectedSong?.code === "error" && "alert")
            }
          />
          <Dialog.Title style={styles.title}>
            {detectedSong?.message === "success - matches found" &&
              "Matches Found"}
            {detectedSong?.message === "success - no matches found" &&
              "No Matches Found"}
            {detectedSong?.code === "error" && "Error"}
          </Dialog.Title>
          <Dialog.Content
            style={{
              maxWidth: 900,
              marginHorizontal: "auto",
            }}
          >
            {detectedSong?.message === "success - matches found" && (
              <Text variant="bodyMedium">
                {detectedSong?.data?.track?.subtitle} by{" "}
                {detectedSong?.data?.track.title}
              </Text>
            )}
          </Dialog.Content>
          <Dialog.Actions>
            {detectedSong?.message === "success - matches found" && (
              <Button
                onPress={() => {
                  dispatch(
                    addSong(
                      detectedSong?.data?.track?.subtitle +
                        " - " +
                        detectedSong?.data?.track.title
                    )
                  );
                }}
              >
                Add Song
              </Button>
            )}
            <Button onPress={() => setModalOpen(false)}>Done</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Button
        icon="microphone-outline"
        mode="outlined"
        uppercase
        style={{ borderRadius: 16, width: "100%", marginBottom: 16 }}
        contentStyle={{ paddingVertical: 8 }}
        loading={loadingState}
        disabled={loadingState}
        onPress={handleDetect}
      >
        {loadingState ? "Listening..." : "Detect Song"}
      </Button>
    </>
  );
};
const styles = StyleSheet.create({
  title: {
    textAlign: "center",
  },
});

export default Detect;
