export const developement = process.env.NODE_ENV === "development";

export const consoleLog = (title: string, data?: any) => {
  if (!data) {
    console.log(`%c ${title}`, "background: #222; color: #bada55");
  }

  if (developement) {
    if (data) {
      console.log(
        `%c ${title} is`,
        "background: #222; color: #bada55",
        data,
        "\n",
        "typeof",
        typeof data
      );
    }
    if (Array.isArray(data)) {
      console.table(title, data);
    }
    if (typeof data === "object") {
      if (data !== null) {
        console.table(data);
        console.log(
          title,
          "Object keys are: ",
          Object.keys(data),
          "\n",
          "Object values are: ",
          Object.values(data)
        );
      }
    }
    if (data instanceof Error) {
      console.error(data);
    }
  }
};
