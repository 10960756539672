import React, { useEffect } from "react";
import { View, StyleSheet, ScrollView, Image, Dimensions } from "react-native";
import { Chip, Text, Banner, TextInput, Button } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import {
  addSong,
  selectCurrentRecommendation,
  selectInputtedSong,
  selectLoading,
  selectPreviousRecommendations,
  setGenre,
  setMood,
} from "../../slices/musicSlice";
import Recommendation from "../recommendation/recommendation";
import SongCard from "../recommendation/songCard";
import { storage } from "../../lib/utils";
import {
  useScrollIntoView,
  wrapScrollView,
} from "react-native-scroll-into-view";
import Detect from "../recommendation/detect";
import { Helmet } from "react-helmet";
import * as Haptics from "expo-haptics";
import { StandardSeo } from "../../constants/seo";
// @ts-ignore
import { logEvent } from "../../utils/analytics";

interface HomeProps {}

const moods: object = {
  Happy: "happy",
  Sad: "sad",
  Angry: "angry",
  Relaxed: "relaxed",
  Energetic: "energetic",
  Excited: "excited",
  Calm: "calm",
  Mellow: "mellow",
  Peaceful: "peaceful",
  Uplifting: "uplifting",
  Motivational: "motivational",
  Epic: "epic",
  Romantic: "romantic",
  Melancholy: "melancholy",
  Dark: "dark",
  Intense: "intense",
  Groovy: "groovy",
  Sensual: "sensual",
  Funky: "funky",
  Chill: "chill",
  Sexy: "sexy",
  Gloomy: "gloomy",
  Festive: "festive",
  Upbeat: "upbeat",
};

const genres: object = {
  "Alternative Rock": "alternative",
  Anime: "anime",
  Blues: "blues",
  Classical: "classical",
  Country: "country",
  Dance: "dance",
  Electronic: "electronic",
  Folk: "folk",
  "Hip-Hop": "hip-hop",
  Indie: "indie",
  Jazz: "jazz",
  Latin: "latin",
  Metal: "metal",
  Pop: "pop",
  "R&B": "rnb",
  Reggae: "reggae",
  Rock: "rock",
  Soul: "soul",
  "Drum & Bass": "drum-and-bass",
  Dubstep: "dubstep",
  House: "house",
  Trance: "trance",
  Trap: "trap",
  World: "world",
};

const CustomScrollView = wrapScrollView(ScrollView);

const HomeView: React.FC<HomeProps> = () => {
  const [visible, setVisible] = React.useState(storage.getBoolean("banner"));
  const [screenWidth, setScreenWidth] = React.useState<number>(
    Dimensions.get("window").width
  );
  const scrollIntoView = useScrollIntoView();
  const cardRef = React.useRef(null);
  const latestRecommendation = useSelector(selectCurrentRecommendation);
  const previousRecommendations = useSelector(selectPreviousRecommendations);
  const selectedGenre = useSelector((state: any) => state.music.genre);
  const selectedMood = useSelector((state: any) => state.music.mood);
  const loadingState = useSelector(selectLoading);
  const inputtedSong = useSelector(selectInputtedSong);
  const dispatch = useDispatch();

  const handleMood = async (mood: string) => {
    dispatch(setMood(mood) as any);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    logEvent("mood", { mood: mood });
  };

  const handleGenre = async (genre: string) => {
    dispatch(setGenre(genre) as any);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    logEvent("genre", { genre: genre });
  };

  const handleBanner = () => {
    setVisible(!visible);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    logEvent("banner", { banner: visible });
  };

  useEffect(() => {
    // scroll to songcard when recommendation is generated
    if (latestRecommendation.length > 0 && cardRef.current) {
      const timer = setTimeout(() => {
        scrollIntoView(cardRef.current);
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [latestRecommendation]);

  useEffect(() => {
    if (visible) {
      storage.set("banner", true);
    }
    if (!visible) {
      storage.set("banner", false);
    }
  }, [visible]);

  useEffect(() => {
    const handleScreenResize = () => {
      setScreenWidth(Dimensions.get("window").width);
    };

    Dimensions.addEventListener("change", handleScreenResize);
  }, []);
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      maxWidth: 900,
      marginHorizontal: "auto",
    },
    bannerContainer: {
      maxWidth: screenWidth - 64,
      width: "100%",
      marginBottom: 16,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      borderRadius: 16,
    },
    banner: {
      width: screenWidth - 32,
      marginVertical: 16,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      borderRadius: 16,
    },
    title: {
      fontSize: 24,
      fontWeight: "900",
      marginBottom: 16,
      maxWidth: screenWidth,
    },
    subtitle: {
      fontWeight: "700",
      marginBottom: 16,
      fontSize: 18,
      maxWidth: screenWidth - 32,
    },
    text: {
      fontSize: 16,
      marginBottom: 16,
      maxWidth: screenWidth - 32,
    },
    list: {
      width: screenWidth - 32,
      marginVertical: 16,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      borderRadius: 16,
    },
  });

  return (
    <>
      <Helmet></Helmet>
      <StandardSeo />
      <View
        style={{
          width: screenWidth - 32,
          marginVertical: 16,
          maxWidth: 900,
          marginHorizontal: "auto",
        }}
      >
        {!visible && (
          <Button
            icon="information-outline"
            mode="contained-tonal"
            onPress={handleBanner}
            style={{ borderRadius: 16, width: 150 }}
          >
            Info
          </Button>
        )}
      </View>
      <Banner
        visible={visible}
        style={styles.banner}
        actions={[{ label: "Love it", onPress: handleBanner }]}
        elevation={1}
      >
        <View style={styles.bannerContainer}>
          <Text
            adjustsFontSizeToFit
            style={styles.title}
            textBreakStrategy="simple"
            role="heading"
          >
            Find the perfect song to play next
          </Text>
          <Text
            adjustsFontSizeToFit
            style={styles.text}
            textBreakStrategy="simple"
          >
            Powered by Open AI's GPT-4, DjGPT is a music recommendation engine
            that uses your favorite songs to find new music you'll love.
          </Text>
          <Text
            adjustsFontSizeToFit
            style={styles.subtitle}
            textBreakStrategy="simple"
          >
            How it works
          </Text>
          <Text
            adjustsFontSizeToFit
            style={styles.text}
            textBreakStrategy="simple"
          >
            DjGPT uses your favorite songs to find new music you'll love. Just
            enter your favorite song and we'll do the rest.
          </Text>
        </View>
      </Banner>
      <View style={styles.container}>
        <Text style={styles.subtitle}>Pick a Mood</Text>
        <Text style={styles.text}>
          Pick a mood to get a recommendation based on the mood you're in.
        </Text>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          {Object.keys(moods).map((mood) => (
            <Chip
              key={mood}
              style={{ margin: 4 }}
              onPress={() => handleMood(mood)}
              selected={mood === selectedMood}
              showSelectedOverlay
              icon={
                mood === selectedMood ? "check-bold" : "emoticon-happy-outline"
              }
            >
              {mood}
            </Chip>
          ))}
        </View>

        <Text style={styles.subtitle}>Pick a Genre</Text>
        <Text style={styles.text}>
          Pick a genre to get a recommendation based on the genre you're in to.
        </Text>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          {Object.keys(genres).map((genre) => (
            <Chip
              key={genre}
              style={{ margin: 4 }}
              onPress={() => handleGenre(genre)}
              selected={genre === selectedGenre}
              showSelectedOverlay
              icon={
                genre === selectedGenre ? "check-bold" : "music-note-outline"
              }
            >
              {genre}
            </Chip>
          ))}
        </View>

        <View
          style={{
            width: screenWidth - 32,
            marginVertical: 16,
            maxWidth: 900,
            marginHorizontal: "auto",
          }}
        >
          <Text style={styles.subtitle}>Search by Song Name</Text>
          <Text style={styles.text}>
            Enter a song name to get a recommendation based on the song you're
            in to.
          </Text>
          <TextInput
            label="Song Name"
            value={inputtedSong}
            onChangeText={(text) => dispatch(addSong(text) as any)}
            style={{
              marginBottom: 16,
              paddingHorizontal: 3,
            }}
            mode="outlined"
            outlineStyle={{
              borderRadius: 16,
            }}
          />
          <Text style={styles.subtitle}>
            Your recommendation will be based on:
          </Text>
          <Text style={styles.text}>
            {selectedMood ? `Mood: ${selectedMood}` : ""}
          </Text>
          <Text style={styles.text}>
            {selectedGenre ? `Genre: ${selectedGenre}` : ""}
          </Text>
          <Text style={styles.text} textBreakStrategy="simple">
            {inputtedSong ? `Song: ${inputtedSong}` : ""}
          </Text>
        </View>
        <Detect />
        <Recommendation />
      </View>
      <View
        style={{
          width: screenWidth - 32,
          marginVertical: 16,
          maxWidth: 900,
          marginHorizontal: "auto",
        }}
      >
        <View ref={cardRef} renderToHardwareTextureAndroid>
          {latestRecommendation.length > 0 &&
            latestRecommendation[0] !== undefined &&
            latestRecommendation.map(
              (
                {
                  artist,
                  song,
                  genre,
                  mood,
                  albumArt,
                  bpm,
                  songLink,
                  songMeta,
                  spotifyURL,
                  youtubeMusicURL,
                }: {
                  artist: string;
                  song?: string;
                  genre?: string;
                  mood?: string;
                  albumArt?: string;
                  bpm?: any;
                  songLink?: string;
                  songMeta?: any;
                  spotifyURL?: string;
                  youtubeMusicURL?: string;
                  index?: number;
                },
                index: number
              ) => {
                return (
                  <SongCard
                    key={index + song}
                    //@ts-ignore
                    artist={artist}
                    song={song}
                    genre={genre}
                    mood={mood}
                    albumArt={songMeta?.albumArt}
                    bpm={bpm}
                    loadingState={loadingState}
                    songLinks={songMeta?.songLinks}
                    spotifyURL={spotifyURL}
                    youtubeMusicURL={youtubeMusicURL}
                  />
                );
              }
            )}
        </View>
        {previousRecommendations &&
          previousRecommendations.length > 0 &&
          previousRecommendations.map(
            (
              {
                artist,
                song,
                theme,
                songMeta,
              }: {
                artist?: string;
                song?: string;
                theme?: any;
                songMeta?: any;
              },
              index
            ) => {
              return (
                <Chip
                  key={index + song}
                  style={{ margin: 4 }}
                  avatar={
                    <Image
                      source={{ uri: songMeta?.albumArt }}
                      style={{
                        width: 32,
                        height: 32,
                        borderRadius: 12,
                        marginVertical: 4,
                      }}
                    />
                  }
                >
                  {artist + " - " + song}
                </Chip>
              );
            }
          )}
      </View>
    </>
  );
};

const Home: React.FC<HomeProps> = () => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      maxWidth: 900,
      marginHorizontal: "auto",
    },
  });
  return (
    <View style={{ ...styles.container }}>
      <CustomScrollView
        scrollIntoViewOptions={{
          animated: true,
          align: "bottom",
        }}
        style={{
          paddingHorizontal: 16,
        }}
      >
        <HomeView />
      </CustomScrollView>
    </View>
  );
};

export default Home;
