import React, { useEffect, useState } from "react";
import {
  PaperProvider,
  MD3LightTheme as DefaultTheme,
  MD3DarkTheme as DarkTheme,
} from "react-native-paper";
import { useSelector } from "react-redux";
import { selectCurrentRecommendation } from "../../slices/musicSlice";
import { useColorScheme } from "react-native";
import { Helmet } from "react-helmet";

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  let colorScheme = useColorScheme();
  const currentRecommendation = useSelector(selectCurrentRecommendation) as any;
  const [currentTheme, setCurrentTheme] = useState(
    colorScheme === "dark" ? DarkTheme.colors : DefaultTheme.colors
  );

  useEffect(() => {
    // ifcurrentRecommendation[0]?.songMeta?.theme?.light exisits join the two objects together
    if (currentRecommendation[0]?.songMeta?.theme?.light) {
      setCurrentTheme((prev) => ({
        ...prev,
        ...(colorScheme === "dark"
          ? currentRecommendation[0]?.songMeta?.theme?.dark
          : currentRecommendation[0]?.songMeta?.theme?.light),
      }));
    } else {
      setCurrentTheme(
        colorScheme === "dark" ? DarkTheme.colors : DefaultTheme.colors
      );
    }
  }, [currentRecommendation, colorScheme]);

  const theme = {
    ...DefaultTheme,
    colors: {
      ...currentTheme,
    },
  };

  return (
    <PaperProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.colors.primary} />
      </Helmet>
      {children}
    </PaperProvider>
  );
};

export default ThemeProvider;
