import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// @ts-ignore
import * as firebaseAuth from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { isWeb } from "../utils";

const firebaseConfig = {
  apiKey: "AIzaSyArExNSauqARIaQ2dvjOwgBDVLMoyW3yaY",
  authDomain: "djgpt23.firebaseapp.com",
  projectId: "djgpt23",
  storageBucket: "djgpt23.appspot.com",
  messagingSenderId: "93843286490",
  appId: "1:93843286490:web:f358d9fcefa56d5feb38e3",
  measurementId: "G-06MTN4SKG3",
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const analytics = isWeb ? getAnalytics(app) : null;
const db = isWeb ? getFirestore() : null;
const storage = isWeb ? getStorage() : null;
const webauth = isWeb ? firebaseAuth.getAuth(app) : null;

export { db, storage, app, webauth, analytics };
