import { configureStore } from "@reduxjs/toolkit";
import musicReducer from "./slices/musicSlice";
import loginReducer from "./slices/loginSlice";
import navReducer from "./slices/navSlice";
export const store = configureStore({
  reducer: {
    music: musicReducer,
    login: loginReducer,
    nav: navReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
      thunk: {
        extraArgument: "getRecommendation",
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
