import { ScrollView, StyleSheet, View } from "react-native";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  detectLoading,
  getDJModeRecommendation,
  selectCurrentDJRecommendation,
  selectDetectLoading,
  selectDjModeLoading,
} from "../../slices/musicSlice";
import { Button, Chip, Text, withTheme } from "react-native-paper";
import * as FileSystem from "expo-file-system";
import { Audio as AudioAV } from "expo-av";
import { isWeb, returnOSValue } from "../../lib/utils";
import SongCard from "../recommendation/songCard";
import { Helmet } from "react-helmet";
import * as Haptics from "expo-haptics";
import { StandardSeo } from "../../constants/seo";
// @ts-ignore
import { logEvent } from "../../utils/analytics";
import { MotiView, useAnimationState } from "moti";
type Props = {
  theme: any;
};

const DjMode = ({ theme }: Props) => {
  const dispatch = useDispatch();
  const djLoading = useSelector(selectDjModeLoading);
  const djRecommendation = useSelector(selectCurrentDJRecommendation) as any;
  const loadingState = useSelector(selectDetectLoading);
  const [detectedSong, setDetectedSong] = React.useState() as any;
  const [recording, setRecording] = React.useState() as any;
  const [error, setError] = React.useState(false);

  const convertWebmToWav = (webm: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
        // console.log(reader.result);
      };
      reader.readAsDataURL(webm);
    });
  };

  const recordAudio = () => {
    logEvent("DJMode", {
      action: "Record Audio",
    });
    return new Promise(async (resolve) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: any[] = [];
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioChunks.push(event.data);
        });
        const start = () => {
          mediaRecorder.start();
          setRecording(true);
        };
        const stop = () => {
          return new Promise((resolve) => {
            mediaRecorder.addEventListener("stop", () => {
              const audioBlob = new Blob(audioChunks);
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              const play = () => {
                audio.play();
              };
              // remove access to microphone

              if (stream.active) {
                stream.getTracks().forEach((track) => {
                  track.stop();
                });
              }

              resolve({ audioBlob, audioUrl, play });
            });
            mediaRecorder.stop();
            setRecording(false);
            // setLoading(true);
            dispatch(detectLoading(true));
          });
        };
        const cancel = () => {
          if (stream.active) {
            stream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          setRecording(false);
        };

        resolve({ start, stop, cancel });
      } catch (err) {
        console.error(err);
      }
    });
  };

  const handleRecommendation = async (song: string) => {
    // console.log("song", song);
    logEvent("DJMode", {
      song: song,
    });
    dispatch(
      getDJModeRecommendation({
        song: song,
      })
    );
    if (song) {
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    }
    return song;
  };

  const sendWav = async (blob: Blob) => {
    setError(false);
    logEvent("DJMode", {
      action: "Send WAV",
    });

    try {
      const response = await fetch(
        //   `${
        //     isWeb ? "https://cors-anywhere.herokuapp.com/" : ""
        //   }${"https://djgpt-reactway.vercel.app/api/music/detectrn"}`,
        // "http://192.168.1.11:3001/api/music/detectrnshazam",
        "https://djgpt-reactway.vercel.app/api/music/detectrnshazam",
        // "https://cheerful-beignet-4abd79.netlify.app/api/music/detectrnshazam",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            song: blob,
            os: returnOSValue({
              androidValue: "android",
              iosValue: "ios",
              webValue: "web",
            }),
          }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      if (data?.message === "success - matches found") {
        dispatch(detectLoading(false));
        setDetectedSong(data);
        // vibrate on match
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        logEvent("DJMode", {
          action: "Match Found",
          label: `${data.data.track.title} - ${data.data.track.subtitle}}`,
        });
        handleRecommendation(
          `${data.data.track.title} - ${data.data.track.subtitle}}`
        );
      }
      if (data?.message === "success - no matches found") {
        dispatch(detectLoading(false));
        logEvent("DJMode", {
          action: "No Match Found",
        });
        setDetectedSong(data);
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
      }
      if (data?.code === "error" || data?.error) {
        setError(true);
        setDetectedSong(data);
        logEvent("DJMode", {
          action: "Error",
        });
        dispatch(detectLoading(false));
      }
    } catch (err) {
      setError(true);

      dispatch(detectLoading(false));
      console.error(err);
    }
  };

  const handleDetect = async () => {
    setDetectedSong(null);
    // console.log("Detect Song");
    logEvent("DJMode", {
      action: "Detect Song",
    });
    try {
      dispatch(detectLoading(true));
      if (!isWeb) {
        // console.log("Requesting permissions..");
        await AudioAV.requestPermissionsAsync();
        await AudioAV.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        });
        // console.log("Starting recording..");
        const { recording } = await AudioAV.Recording.createAsync(
          AudioAV.RecordingOptionsPresets.HIGH_QUALITY
        );
        // console.log("recording", recording);

        setRecording(recording) as Blob;
        // console.log("Recording started");
        setTimeout(async () => {
          // console.log("Stopping recording..");
          recording.stopAndUnloadAsync();
          // console.log("Recording stopped and stored at", recording.getURI());

          if (recording !== null) {
            if (isWeb) {
              // console.log("isWeb");
              const blob = await fetch(recording.getURI() as string).then((r) =>
                r.blob()
              );
              // console.log("blob", blob);
              sendWav(blob);
            }
            if (!isWeb) {
              // console.log("notWeb");
              // convert to blob to send to server

              const info = await FileSystem.getInfoAsync(
                recording.getURI() as string
              );
              // console.log(`FILE INFO: ${JSON.stringify(info)}`);

              const blob = await FileSystem.readAsStringAsync(
                recording.getURI() as string,
                {
                  encoding: FileSystem.EncodingType.Base64,
                }
              );

              // console.log("blob", blob);
              // @ts-ignore
              sendWav(blob);
            }
          }
        }, 3800);
      }
      if (isWeb) {
        // console.log("isWeb");
        const recorder = (await recordAudio()) as any;
        recorder.start();
        setTimeout(async () => {
          const audio = await recorder.stop();
          // console.log("audio", audio);
          const blob = await convertWebmToWav(audio.audioBlob);
          // console.log("blob", blob);
          // @ts-ignore
          sendWav(blob);
        }, 3800);
      }
    } catch (err) {
      dispatch(detectLoading(false));
      console.error("Failed to start recording", err);
    }
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Helmet></Helmet>
      <StandardSeo title="DJ Mode | DjGPT" />

      <Text style={styles.title}>DJ Mode</Text>
      <View style={styles.djContainer}>
        <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            marginBottom: 16,
            paddingHorizontal: 16,
          }}
        >
          DJ Mode is a focused way to get recommended music based on what you're
          already playing. It's a great way to discover new music and artists.
        </Text>
        <Button
          icon="microphone-outline"
          mode="contained-tonal"
          uppercase
          style={{ borderRadius: 16, width: "100%", marginBottom: 16 }}
          contentStyle={{ paddingVertical: 8, width: "100%" }}
          loading={loadingState || djLoading}
          disabled={loadingState || djLoading}
          onPress={handleDetect}
        >
          {!djLoading && <>{loadingState ? "Listening..." : "Detect Song"}</>}
          {djLoading && "Thinking..."}
        </Button>
      </View>

      {detectedSong?.message === "success - matches found" && (
        <>
          <MotiView
            from={{
              translateY: [0],
            }}
            animate={{
              translateY: [0, 7, -5, 0],
            }}
            transition={{
              type: "spring",
              duration: 150,
            }}
          >
            <Chip
              mode="outlined"
              selectedColor={theme.colors.tertiary}
              icon="music-note-sixteenth"
              theme={{
                colors: {
                  text: theme.colors.tertiary,
                  primary: theme.colors.tertiary,
                  secondary: theme.colors.tertiary,
                },
              }}
            >
              Matches Found
            </Chip>
          </MotiView>
          <Text variant="bodyMedium" style={styles.songTitle}>
            {detectedSong?.data?.track.subtitle} by{" "}
            {detectedSong?.data?.track?.title}
          </Text>
        </>
      )}

      {detectedSong?.message === "success - no matches found" && (
        <MotiView
          from={{
            translateX: [0],
          }}
          animate={{
            translateX: [0, 7, -5, 0],
          }}
          key={detectedSong?.message}
          transition={{
            type: "spring",
            duration: 100,
            repeat: 2,
          }}
        >
          <Chip
            mode="outlined"
            selectedColor={theme.colors.error}
            theme={{
              colors: {
                text: theme.colors.error,
                primary: theme.colors.error,
                secondary: theme.colors.error,
              },
            }}
            style={styles.detectedTitle}
            icon={"alert-circle-outline"}
          >
            No Matches Found
          </Chip>
        </MotiView>
      )}

      {detectedSong?.code === "error" || error ? (
        <Text variant="bodyMedium" style={styles.detectedTitle}>
          Error
        </Text>
      ) : null}

      {djRecommendation?.songMeta?.albumArt && (
        <SongCard
          //@ts-ignore
          artist={djRecommendation.artist}
          song={djRecommendation.song}
          genre={djRecommendation?.genre}
          mood={djRecommendation?.mood}
          albumArt={djRecommendation?.songMeta?.albumArt}
          bpm={djRecommendation?.bpm}
          loadingState={loadingState}
          songLinks={djRecommendation?.songMeta?.songLinks}
          spotifyURL={djRecommendation?.songMeta?.songLinks?.spotifyURL}
          youtubeMusicURL={
            djRecommendation?.songMeta?.songLinks?.youtubeMusicURL
          }
        />
      )}
    </ScrollView>
  );
};

export default withTheme(DjMode);

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    paddingHorizontal: 16,
    maxWidth: 800,
    marginHorizontal: "auto",
  },
  djContainer: {
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    paddingHorizontal: 16,
    maxWidth: 500,
    marginHorizontal: "auto",
  },
  image: {
    width: "100%",
    height: 300,
    objectFit: "cover",
  },
  songTitle: {
    fontSize: 16,
    textAlign: "center",
    marginVertical: 16,
    fontWeight: "bold",
  },
  detectedTitle: {
    textAlign: "center",
    marginVertical: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "900",
    marginBottom: 16,
  },
  subtitle: {
    fontWeight: "700",
    marginBottom: 16,
    fontSize: 18,
  },
  text: {
    fontSize: 16,
    marginBottom: 16,
  },
});
