import { Easing, StyleSheet, View } from "react-native";
import { withTheme } from "react-native-paper";
import { createMaterialBottomTabNavigator } from "react-native-paper/react-navigation";
import Home from "../home/Home";
import DjMode from "../djmode/DjMode";
import Recents from "../recents/Recents";
import Account from "../account/Account";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import * as Haptics from "expo-haptics";
// @ts-ignore
import { logEvent } from "../../utils/analytics";
import { isWeb } from "../../lib/utils";
import { getRecentRecommendations } from "../../slices/musicSlice";
import { useDispatch } from "react-redux";
type Props = {
  index: number;
  routes: any;
  renderScene: any;
  setIndex: number;
};

const BottomNav = ({ theme }) => {
  const Tab = createMaterialBottomTabNavigator();
  const dispatch = useDispatch();

  return (
    <>
      <Tab.Navigator
        initialRouteName="Home"
        barStyle={{
          backgroundColor: theme.colors.surface,
          opacity: 0.95,
        }}
        shifting={true}
        sceneAnimationEnabled={false}
        backBehavior="initialRoute"
        sceneAnimationEasing={Easing.out(Easing.poly(4))}
        // vibrate on tab press
        screenListeners={{
          tabPress: (tab) => {
            !isWeb && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
            logEvent("tab_press", {
              screen: "bottom_nav",
              selected_tab: tab.target,
            });
            if (tab.target.includes("Recents")) {
              dispatch(getRecentRecommendations());
            }
          },
        }}

        // screenOptions={({ route }) => ({
        //   tabBarBadge: route.name === "Recents" ? recents : null,
        // })}
      >
        <Tab.Screen
          name="Home"
          component={Home}
          options={{
            title: "Home",
            tabBarIcon: ({ color, focused }) => (
              <View style={{ alignItems: "center" }}>
                <MaterialCommunityIcons
                  name={focused ? "home-variant" : "home-variant-outline"}
                  color={color}
                  size={26}
                />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="DJ-Mode"
          component={DjMode}
          options={{
            tabBarIcon: ({ color, focused }) => (
              <View style={{ alignItems: "center" }}>
                <MaterialCommunityIcons
                  name={focused ? "music-note" : "music-note-outline"}
                  color={color}
                  size={26}
                />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Recents"
          component={Recents}
          options={{
            tabBarIcon: ({ color, focused }) => (
              <View style={{ alignItems: "center" }}>
                <MaterialCommunityIcons
                  name={
                    focused
                      ? "skip-previous-circle"
                      : "skip-previous-circle-outline"
                  }
                  color={color}
                  size={26}
                />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Account"
          component={Account}
          options={{
            tabBarIcon: ({ color, focused }) => (
              <View style={{ alignItems: "center" }}>
                <MaterialCommunityIcons
                  name={focused ? "account" : "account-outline"}
                  color={color}
                  size={26}
                />
              </View>
            ),
          }}
        />
      </Tab.Navigator>
    </>
  );
};

export default withTheme(BottomNav);

const styles = StyleSheet.create({});
