import fetchRecommendation from "../lib/music/recommender";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { isWeb } from "../lib/utils";
// @ts-ignore
import { uploadRecentRecommendation, getRecents } from "../utils/recents";
import { get } from "react-native/Libraries/TurboModule/TurboModuleRegistry";
// @ts-ignore
import UserState from "../lib/login/UserState";

export interface musicState {
  recommendations: any[];
  djRecommendations: any[];
  recents: any[];
  inputtedSong: string;
  loading: boolean;
  detectLoading: boolean;
  djModeLoading: boolean;
  recentLoading: boolean;
  djMode: boolean;
  genre: string;
  mood: string;
}

const initialState: musicState = {
  recommendations: [],
  djRecommendations: [],
  recents: [],
  inputtedSong: "",
  loading: false,
  detectLoading: false,
  djModeLoading: false,
  recentLoading: false,
  djMode: false,
  genre: "Hip-Hop",
  mood: "Happy",
};

export const musicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    // add theme to song object in recommendations array
    addTheme: (state, action) => {
      const { song, theme } = action.payload;
      const index = state.recommendations.findIndex(
        (recommendation) => recommendation.song === song
      );
      state.recommendations[index].theme = theme;
    },
    addDJTheme: (state, action) => {
      const { song, theme } = action.payload;
      const index = state.djRecommendations.findIndex(
        (recommendation) => recommendation.song === song
      );
      state.djRecommendations[index].theme = theme;
    },
    detectLoading: (state, action) => {
      state.detectLoading = action.payload;
    },
    addSong: (state, action) => {
      state.inputtedSong = action.payload;
    },
    toggleDjMode: (state) => {
      state.djMode = !state.djMode;
    },
    setGenre: (state, action) => {
      state.genre = action.payload;
    },
    setMood: (state, action) => {
      state.mood = action.payload;
    },
    setRecents: (state, action) => {
      state.recents = action.payload.recents;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecommendation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRecommendation.fulfilled, (state, action) => {
      state.loading = false;
      state.recommendations.push(action.payload);
    });
    builder.addCase(getRecommendation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getDJModeRecommendation.pending, (state) => {
      state.djModeLoading = true;
    });
    builder.addCase(getDJModeRecommendation.fulfilled, (state, action) => {
      state.djModeLoading = false;
      state.djRecommendations = [action.payload];
    });
    builder.addCase(getDJModeRecommendation.rejected, (state) => {
      state.djModeLoading = false;
    });
    builder.addCase(getRecentRecommendations.pending, (state) => {
      state.recentLoading = true;
    });
    builder.addCase(getRecentRecommendations.fulfilled, (state, action) => {
      state.recentLoading = false;
      state.recents = action.payload.recents;
    });
    builder.addCase(getRecentRecommendations.rejected, (state) => {
      state.recentLoading = false;
    });
  },
});

export const getRecommendation: any = createAsyncThunk(
  "music/getRecommendation",
  async (
    {
      genre,
      mood,
      song,
      previousSongs,
    }: {
      genre?: string;
      mood?: string;
      song?: string;
      previousSongs?: any[];
    },
    thunkAPI
  ) => {
    try {
      const response = await fetchRecommendation({
        genre: genre,
        mood: mood,
        song: song,
        previousSongs: previousSongs,
      });

      const res = await response;

      let songMeta: any;
      try {
        const response = await fetch(
          // `${
          //   isWeb ? "https://cors-anywhere.herokuapp.com/" : ""
          // }https://djgpt-reactway.vercel.app/api/music/song-meta`,
          "https://djgpt-reactway.vercel.app/api/music/song-meta",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              song: res.artist + " " + res.song,
            }),
          } as any
        );
        const data = await response.json();
        console.log("song meta", data);
        songMeta = data;

        // access state from loginslice to get user id
        // @ts-ignore
        const userId = thunkAPI.getState().login.login.user.uid as string;
        if (!userId) {
          console.log("no user id");
        }
        if (userId) {
          uploadRecentRecommendation(userId, data);
          getRecentRecommendations();
        }

        // console.log(albumArtId);
      } catch (err) {
        // console.log("error getting album art id");
        console.error(err);
      }
      res.songMeta = songMeta;

      return res;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getDJModeRecommendation: any = createAsyncThunk(
  "music/getDJModeRecommendation",
  async (
    {
      genre,
      mood,
      song,
      previousSongs,
    }: {
      genre?: string;
      mood?: string;
      song?: string;
      previousSongs?: any[];
    },
    thunkAPI
  ) => {
    try {
      const response = await fetchRecommendation({
        genre: genre,
        mood: mood,
        song: song,
        previousSongs: previousSongs,
      });

      const res = await response;

      let songMeta: any;
      try {
        const response = await fetch(
          // `${
          //   isWeb ? "https://cors-anywhere.herokuapp.com/" : ""
          // }https://djgpt-reactway.vercel.app/api/music/song-meta`,
          "https://djgpt-reactway.vercel.app/api/music/song-meta",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              song: res.artist + " " + res.song,
            }),
          } as any
        );
        const data = await response.json();
        songMeta = data;
        // access state from loginslice to get user id
        // @ts-ignore
        const userId = thunkAPI.getState().login.login.user.uid as string;
        if (!userId) {
          console.log("no user id");
        }
        if (userId) {
          uploadRecentRecommendation(userId, data);
          getRecentRecommendations();
        }

        // console.log(albumArtId);
      } catch (err) {
        // console.log("error getting album art id");

        console.error(err);
        return {
          message: "No results found",
        };
      }
      res.songMeta = songMeta;
      return res;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getRecentRecommendations: any = createAsyncThunk(
  "music/getRecentRecommendations",
  async (_, thunkAPI) => {
    try {
      if (UserState) {
        // @ts-ignore
        const userId = thunkAPI.getState().login?.login?.user?.uid as string;
        if (!userId) {
          console.log("no user id");
          return { recents: [] };
        }
        const response = await getRecents(userId);
        const recents = await response;
        if (!recents) {
          return { recents: [] };
        }
        // sort by date which is a key in the object

        return { recents };
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const selectCurrentRecommendation = createSelector(
  (state: RootState) => state.music.recommendations,
  (recommendations) => recommendations.slice(-1)
);

export const selectCurrentDJRecommendation = createSelector(
  (state: RootState) => state.music.djRecommendations,
  (djRecommendations) => djRecommendations.slice(-1)[0]
);

export const selectPreviousRecommendations = createSelector(
  (state: RootState) => state.music.recommendations,
  (recommendations) => recommendations.slice(0, -1)
);

export const selectRecommendations = (state: RootState) =>
  state.music.recommendations;

export const selectDjRecommendations = (state: RootState) =>
  state.music.djRecommendations;

export const selectLoading = (state: RootState) => state.music.loading;

export const selectDetectLoading = (state: RootState) =>
  state.music.detectLoading;

export const selectDjModeLoading = (state: RootState) =>
  state.music.djModeLoading;

export const selectInputtedSong = (state: RootState) =>
  state.music.inputtedSong;

export const selectDjMode = (state: RootState) => state.music.djMode;

export const selectGenre = (state: RootState) => state.music.genre;

export const selectMood = (state: RootState) => state.music.mood;

export const selectRecents = (state: RootState) => state.music.recents;

// create sorted recents selector

export const selectSortedRecents = createSelector(
  (state: RootState) => state.music.recents,
  (recents) => {
    const sortedDates = Object.keys(recents).sort((a, b) => {
      const sortedDates = new Date(b).getTime() - new Date(a).getTime();

      return sortedDates;
    });

    const sortedRecentsObject = sortedDates.reduce((acc: any, cur: any) => {
      acc[cur] = recents[cur];
      // reverse the order of the songs in the array
      return acc;
    }, {});

    return sortedRecentsObject;
  }
);

export const selectRecentCount = (state: RootState) =>
  state.music.recents.length;
export const selectRecentLoading = (state: RootState) =>
  state.music.recentLoading;

// Action creators are generated for each case reducer function
export const {
  addTheme,
  addDJTheme,
  addSong,
  toggleDjMode,
  setGenre,
  setMood,
  detectLoading,
  setRecents,
} = musicSlice.actions;

export default musicSlice.reducer;
