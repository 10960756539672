import { StatusBar, StyleSheet } from "react-native";
import { Appbar, Icon, withTheme } from "react-native-paper";
import BottomNav from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
import { selectIndex, setIndex } from "../../slices/navSlice";
import Logo from "../assets/Logo";
import { isDarkMode } from "../../lib/utils";
import { selectLogin } from "../../slices/loginSlice";
import Splash from "../splash/Splash";

type Props = {
  theme: any;
};

const Navigation = ({ theme }: Props) => {
  const dispatch = useDispatch();
  const index = useSelector(selectIndex);
  const _goBack = () => dispatch(setIndex(0));
  const userState = useSelector(selectLogin);

  return (
    <>
      {userState && userState?.uid ? (
        <>
          <Appbar.Header
            dark={isDarkMode}
            theme={theme}
            style={{
              backgroundColor: theme.colors.background,
              borderBottomWidth: 0,
            }}
          >
            <StatusBar
              barStyle={!isDarkMode ? "light-content" : "dark-content"}
              backgroundColor={theme.colors.primary}
            />

            <Appbar.Content
              title={
                <Icon
                  size={42}
                  source={() => <Logo color={theme.colors.primary} />}
                />
              }
              mode="center-aligned"
              subtitle="DjGPT"
              style={{ alignItems: "center" }}
            />
          </Appbar.Header>
          <BottomNav />
        </>
      ) : (
        <>
          <StatusBar
            barStyle={!isDarkMode ? "light-content" : "dark-content"}
            backgroundColor={theme.colors.primary}
          />
          <Splash />
        </>
      )}
    </>
  );
};

export default withTheme(Navigation);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  logo: {
    width: 26,
    height: 26,
    marginRight: 10,
  },
});
