import { Animated } from "react-native";

export const fadeIn = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });
};

export const fadeOut = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
  });
};

export const slideIn = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
  });
};

export const slideOut = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 100,
    duration: 500,
    useNativeDriver: true,
  });
};

export const slideUp = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
  });
};

export const slideDown = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 100,
    duration: 500,
    useNativeDriver: true,
  });
};

export const GrowIn = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });
};

export const GrowOut = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
  });
};

export const ShrinkIn = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 1,
    duration: 500,
    useNativeDriver: true,
  });
};

export const ShrinkOut = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 0,
    duration: 500,
    useNativeDriver: true,
  });
};
