import { Animated, Linking, StyleSheet, View } from "react-native";
import { IconButton } from "react-native-paper";
import Deezer from "./icons/Deezer";
import Tidal from "./icons/Tidal";
import { withTheme } from "react-native-paper";
import React, { useRef } from "react";
import { fadeIn } from "../../constants/animations";
import { MotiView, useAnimationState } from "moti";

type Props = {
  songLinks: any;
  classname?: string;
  theme: any;
};

function SongLinks({ songLinks, classname, theme }: Props) {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    icon: {
      marginRight: 8,
    },
  });

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const cardFadeIn = fadeIn(fadeAnim);

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 5000,
      useNativeDriver: true,
    }).start();
  }, []);

  const AnimateIn = useAnimationState({
    from: {
      translateY: 50,
      opacity: 0,
      transition: {
        type: "timing",
        duration: 500,
      },
    },
    to: {
      translateY: 0,
      opacity: 1,
      transition: {
        type: "timing",
        duration: 500,
      },
    },
  });

  return (
    <View style={styles.container}>
      {songLinks &&
        songLinks.map((link: any, index: number) => {
          switch (link.type) {
            case "SPOTIFY":
              return (
                <MotiView
                  key={link.type}
                  state={AnimateIn}
                  transition={{
                    type: "timing",
                    duration: 500,
                    delay: index * 100,
                  }}
                >
                  <IconButton
                    icon="spotify"
                    onPress={() => Linking.openURL(link.actions[0].uri)}
                  />
                </MotiView>
              );
            case "YOUTUBEMUSIC":
              return (
                <MotiView
                  key={link.type}
                  state={AnimateIn}
                  transition={{
                    type: "timing",
                    duration: 500,
                    delay: index * 100,
                  }}
                >
                  <IconButton
                    key={link.type}
                    icon="youtube"
                    onPress={() => Linking.openURL(link.actions[0].uri)}
                  />
                </MotiView>
              );
            case "DEEZER":
              return (
                <MotiView
                  key={link.type}
                  state={AnimateIn}
                  transition={{
                    type: "timing",
                    duration: 500,
                    delay: index * 100,
                  }}
                >
                  <IconButton
                    icon={() => (
                      <Deezer color={theme.colors.onSurfaceVariant} />
                    )}
                    onPress={() => Linking.openURL(link.actions[0].uri)}
                  />
                </MotiView>
              );
            case "TIDAL":
              return (
                <MotiView
                  key={link.type}
                  state={AnimateIn}
                  transition={{
                    type: "timing",
                    duration: 500,
                    delay: index * 100,
                  }}
                >
                  <IconButton
                    icon={() => <Tidal color={theme.colors.onSurfaceVariant} />}
                    onPress={() => Linking.openURL(link.actions[0].uri)}
                  />
                </MotiView>
              );
            case "APPLE_MUSIC":
              return (
                <MotiView
                  key={link.type}
                  state={AnimateIn}
                  transition={{
                    type: "timing",
                    duration: 500,
                    delay: index * 100,
                  }}
                >
                  <IconButton
                    icon="apple"
                    onPress={() => Linking.openURL(link.actions[0].uri)}
                  />
                </MotiView>
              );

            default:
              return null;
          }
        })}
    </View>
  );
}

export default withTheme(SongLinks);
