// @ts-ignore

import { analytics } from "../lib/firebase/firebase";
import { consoleLog, developement } from "./helpers";
import {
  logEvent as logEventFb,
  setUserId as setUserIdFb,
  setUserProperties as setUserPropertiesFb,
  setAnalyticsCollectionEnabled as setAnalyticsCollectionEnabledFb,
} from "firebase/analytics";

export const logEvent = (event: string, params?: any) => {
  if (developement) {
    consoleLog("logEvent", { event, params });
  }
  logEventFb(analytics, event, params);
};

export const setUserId = (id: string) => {
  if (developement) {
    consoleLog("setUserId", id);
  }
  setUserIdFb(analytics, id);
};

export const setUserProperty = (name: string, value: string) => {
  if (developement) {
    consoleLog("setUserProperty", { name, value });
  }
  setUserPropertiesFb(analytics, { [name]: value });
};

export const setAnalyticsCollectionEnabled = (enabled: boolean) => {
  if (developement) {
    consoleLog("setAnalyticsCollectionEnabled", enabled);
  }
  setAnalyticsCollectionEnabledFb(analytics, enabled);
};
