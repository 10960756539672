import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogOff, Login, selectLogin } from "../../slices/loginSlice";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { webauth, db } from "../firebase/firebase.web";
import { isWeb } from "../utils";
import { setUserId } from "../../utils/analytics.web";
// import auth from "@react-native-firebase/auth";

type Props = {};

const UserState = (props: Props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const userState = useSelector(selectLogin);

  const uploadUser = useCallback(async () => {
    if (userState) {
      const userRef = doc(db, "users", userState.uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        await setDoc(
          userRef,
          {
            name: userState.displayName,
            email: userState.email,
            photoURL: userState.photoURL,
          },
          { merge: true }
        );
      } else {
        await setDoc(
          userRef,
          {
            name: userState.displayName,
            email: userState.email,
            photoURL: userState.photoURL,
          },
          { merge: true }
        );
      }
    }
  }, [userState]);

  useMemo(() => {
    if (user) {
      dispatch(
        Login({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified ? "Verified" : "Not Verified",
        })
      );
      setUserId(user.email);
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (isWeb) {
      onAuthStateChanged(webauth, (user) => {
        if (user && !user.isAnonymous) {
          setUser(user);
          uploadUser();
        }
        if (!user) {
          dispatch(LogOff());
          setUser(null);
        }
      });
    }
  }, [uploadUser, dispatch]);

  return <></>;
};

export default UserState;
