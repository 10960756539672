import { ScrollView, StyleSheet, View } from "react-native";
import { Text, Avatar } from "react-native-paper";
import { useSelector } from "react-redux";
import { selectLogin } from "../../slices/loginSlice";
import { Helmet } from "react-helmet";
import { seoTitle } from "../../lib/constants";
import { isWeb } from "../../lib/utils";
// @ts-ignore
import SignIn from "../login/SignIn";
import { StandardSeo } from "../../constants/seo";

type Props = {};

const Account = (props: Props) => {
  const userState = useSelector(selectLogin);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Helmet></Helmet>

      <StandardSeo title="Account | DjGPT" />
      <View style={styles.loginContainer}>
        <Text style={styles.title}>Account</Text>
        {userState?.photoURL ? (
          <Avatar.Image
            size={128}
            source={{ uri: userState?.photoURL }}
            style={{ marginVertical: 16 }}
          />
        ) : (
          <Avatar.Icon
            size={128}
            icon="account"
            style={{ marginVertical: 16 }}
          />
        )}
        {userState && (
          <>
            <Text style={styles.subtitle}>
              Logged in as {userState?.displayName}
            </Text>
            <Text>Email: {userState?.email}</Text>
          </>
        )}
        {!userState && (
          <>
            <Text style={styles.subtitle}>Not logged in</Text>
          </>
        )}
      </View>
      <SignIn />
    </ScrollView>
  );
};

export default Account;

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    gap: 16,
    paddingHorizontal: 16,
    maxWidth: 800,
    marginHorizontal: "auto",
    // backgroundColor: theme.colors.background,
  },
  loginContainer: {
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
    marginHorizontal: "auto",
    // backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: 24,
    fontWeight: "900",
    marginBottom: 16,
  },
  subtitle: {
    fontWeight: "700",
    marginBottom: 16,
    fontSize: 18,
  },
  text: {
    fontSize: 16,
    marginBottom: 16,
  },
});
