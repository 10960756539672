import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LoginState {
  login: {
    user?: any;
  };
}

const initialState: LoginState = {
  login: {},
};

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    Login: (state, action) => {
      state.login.user = action.payload;
    },
    LogOff: (state) => {
      state.login = initialState.login;
    },
  },
});
// Selectors
export const selectLogin = (state: RootState) => state.login.login.user;

//  Actions
export const { Login, LogOff } = LoginSlice.actions;

export default LoginSlice.reducer;
