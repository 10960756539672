import React from "react";
import { View, StyleSheet, Animated } from "react-native";
import { Card, Text } from "react-native-paper";
import SongLinks from "./songLinks";
import { withTheme } from "react-native-paper";
import { fadeIn } from "../../constants/animations";

type SongCardProps = {
  artist?: string;
  song?: string;
  albumArt?: string;
  genre?: string;
  mood?: string;
  bpm?: number;
  songLink?: string;
  loadingState?: boolean;
  spotifyURL?: string;
  youtubeMusicURL?: string;
  songLinks?: any;
  theme?: any;
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: 300,
    objectFit: "cover",
  },
});

const SongCard: React.FC<SongCardProps> = ({
  artist,
  song,
  albumArt,
  genre,
  mood,
  bpm,
  songLinks,
  loadingState,
  theme,
}: any) => {
  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  const cardFadeIn = fadeIn(fadeAnim);

  React.useEffect(() => {
    cardFadeIn.start();
  }, []);

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
        overflow: "hidden",
      }}
    >
      <Card
        theme={theme}
        style={{ borderRadius: 16, marginBottom: 16, width: "100%" }}
        mode="contained"
      >
        {albumArt !== null ? (
          <Card.Cover source={{ uri: albumArt }} style={styles.image} />
        ) : (
          <Card.Cover
            source={require("../../assets/albumart-missing.jpeg")}
            style={styles.image}
          />
        )}
        <Card.Title
          title={
            <Text
              style={{
                color: theme.colors.onSurfaceVariant,
                fontWeight: "bold",
                fontSize: 24,
              }}
            >
              {song}
            </Text>
          }
          subtitle={artist}
          right={(props) => (
            <Text
              style={{
                color: theme.colors.onSurfaceVariant,
              }}
              {...props}
            >
              {bpm} BPM
            </Text>
          )}
          style={{ paddingHorizontal: 16 }}
        />
        <Card.Actions>
          <SongLinks songLinks={songLinks} />
        </Card.Actions>
      </Card>
    </Animated.View>
  );
};

export default withTheme(SongCard);
