import React from "react";
import { StyleSheet, Text } from "react-native";
import { Provider } from "react-redux";
import { store } from "../store";
import ThemeProvider from "../components/theme/ThemeProvider";
import { withTheme } from "react-native-paper";
import Navigation from "../components/layout/Navigation";
import UserState from "../lib/login/UserState";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { seoTitle } from "../lib/constants";
import { View } from "react-native";
import "react-native-reanimated";
import "react-native-gesture-handler";

function App({ theme }) {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: 16,
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
    },
    logo: {
      color: theme.colors.onBackground,
      fill: theme.colors.onBackground,
      width: 26,
      height: 26,
      marginRight: 10,
    },
  });

  const Loader = () => (
    <View style={styles.container}>
      <Text style={styles.title}>Loading...</Text>
    </View>
  );

  return (
    <Provider store={store}>
      <ThemeProvider>
        <NavigationContainer
          linking={{
            prefixes: ["https://djgpt.co.uk", "djgpt://"],
            config: {
              screens: {
                Home: "",
                DjMode: "DJ-Mode",
                Account: "account",
                Recents: "recents",
                Splash: "splash",
              },
            },
          }}
          documentTitle={{
            formatter: (options, route) =>
              `DjGPT - ${options?.title ?? route?.name} - ${seoTitle}`,
          }}
          fallback={<Loader />}

        >
          <Navigation />
          <UserState />
        </NavigationContainer>
      </ThemeProvider>
    </Provider>
  );
}

export default withTheme(App);
