import ms from "ms";
import { Platform } from "react-native";
import { Appearance } from "react-native";
import { MMKV } from "react-native-mmkv";
import * as Network from "expo-network";

export const isWeb = Platform.OS === "web";
export const isNative = !isWeb;
export const isServer = typeof window === "undefined";
export const isAndroid = Platform.OS === "android";
export const isIOS = Platform.OS === "ios";
export const isDarkMode = Appearance.getColorScheme() === "dark";


export const checkNetwork = async () => {
  Network.getNetworkStateAsync().then((state) => {
    console.log("Connection type", state.type);
    console.log("Is connected?", state.isConnected);
  });
};

export const returnOSValue = ({
  iosValue,
  androidValue,
  webValue,
}: {
  iosValue: any;
  androidValue: any;
  webValue: any;
}) => {
  if (isNative) {
    if (isIOS) return iosValue;
    if (isAndroid) return androidValue;
  }

  return webValue;
};

export const timeAgo = (timestamp: Date, timeOnly?: boolean): string => {
  if (!timestamp) return "never";
  return `${ms(Date.now() - new Date(timestamp).getTime())}${
    timeOnly ? "" : " ago"
  }`;
};

export const storage = new MMKV();

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export function nFormatter(num: number, digits?: number) {
  if (!num) return "0";
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol
    : "0";
}

export function capitalize(str: string) {
  if (!str || typeof str !== "string") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const truncate = (str: string, length: number) => {
  if (!str || str.length <= length) return str;
  return `${str.slice(0, length)}...`;
};
